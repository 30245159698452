.row {
    display: flex;
    margin: auto;
    flex-direction: row;
    justify-content: center;
  }

.header-style {
    color: var(--color-primary);
    font-family: Helvetica, sans-serif;
    
}

.subheader {
    font-family: Helvetica, sans-serif;
    display: flex;
    justify-content: center;
}

.margin-top {
    margin-top: 16px;
  }
  

  
.column {
    padding:0 16px;
    margin-bottom: 32px;
}
