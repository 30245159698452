.link-list {
    margin-left: 32px;
    margin-right: 32px;
    display: flex;
    flex-direction: column;
}

.list-item {
    margin-top: 10px;
    margin-bottom: 10px;
}

.list-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.arrow-button {
    align-content: center;
    margin-left: 8px;
}

.link-button {
    margin-left: 8px;
    flex-grow: 1;
}

@media all and (min-width: 875px) {
    .link-list {
        width: 33vw;
        margin: auto;
      } 
  }
  
  @media all and (max-width: 875px) {
    .link-list {

    }
    
}