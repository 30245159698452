body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  --teal-50: #E0F2F1;
  --teal-100: #B2DFDB;
  --teal-200: #80CBC4;
  --teal-300: #4DB6AC;
  --teal-400: #26A69A;

  --light-green-50: #F1F8E9;
  --light-green-100: #DCEDC8;
  --light-green-200: #C5E1A5;

  --orange-50: #FFF3E0;
  --orange-100: #FFE0B2;
  --orange-200: #FFCC80;
  
  --blue-50: #E3F2FD;
  --blue-100: #BBDEFB;
  --blue-200: #90CAF9;
  --blue-300: #64B5F6;
  
  --red-50: #FFEBEE;
  --red-100: #FFCDD2;
  --red-200: #EF9A9A;
  
  --grey-50: #FAFAFA;
  --grey-100: #F5F5F5;
  --grey-200: #eeeeee;
  --grey-300: #E0E0E0;
  --grey-400: #BDBDBD;
  
  --deep-purple-50: #EDE7F6;
  --deep-purple-100: #D1C4E9;
  --deep-purple-200: #B39DDB;

  --color-primary: var(--teal-400);
  --color-primary-hover: var(--blue-50);
  --color-primary-active: var(--blue-100);
  --color-primary-dark: var(--blue-300);
  background: #eee !important;
}


html, body, #app {
  background: #eee;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-content-margin {
  padding-top: 120px;
}

.section-margin-top {
  margin-top: 32px;
}

.section-margin-start {
  margin-left: 32px;
}

.margin-top {
  margin-top: 16px;
}

.margin-bottom {
  margin-bottom: 16px;
}

.list-margin-top {
  margin-top: 8px;
}

.double-margin-top {
  margin-top: 16px;
}

.margin-page-bottom {
  margin-bottom: 32px
}

.center-text {
  text-align: center;
  vertical-align: middle;
}