.header {
    background-color: var(--color-primary);
    padding-left: 32px;
    padding-top: 24px;
    padding-right: 32px;
    padding-bottom: 8px;
    justify-content: space-between;
    width: 100%;
    font-family: Helvetica, sans-serif;
    position: fixed;

}

.right-hand-button {
    margin-left: 8px;
}

a {
    color: #fff;
}